type RecordKey = string

type RecordData = {
  quantity: number
  price: number
}

type StateData = {
  quantity: number
  total: number
  records: Map<string, RecordData>
}

class ImmutableStore {
  #records = new Map<RecordKey, RecordData>()

  get state(): Readonly<StateData> {
    if (this.#records.size < 1) {
      return Object.freeze({
        quantity: 0,
        total: 0,
        records: this.#records,
      })
    }

    const { quantity, total } = [...this.#records.values()].reduce(
      (
        accumulator: {
          quantity: StateData['quantity']
          total: StateData['total']
        },
        { quantity, price },
      ) => {
        return {
          quantity: accumulator.quantity + quantity,
          total: accumulator.total + quantity * price,
        }
      },
      {
        quantity: 0,
        total: 0,
      },
    )

    return Object.freeze({
      quantity,
      total,
      records: this.#records,
    })
  }

  updateRecord(
    key: RecordKey,
    data: {
      quantity: RecordData['quantity']
      price?: RecordData['price']
    },
  ): void {
    const { quantity, price = 0 } = data

    if (!this.#records.has(key)) {
      this.#records.set(key, {
        quantity,
        price,
      })

      return
    }

    const record = this.#records.get(key)

    this.#records.set(key, {
      ...record,
      quantity,
    })
  }
}

export default () => new ImmutableStore()
