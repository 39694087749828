export const PDP_HERO_SET = 'pdp:hero:set'

export const PDP_DYNAMIC_BANNER_SET = 'pdp:dynamic-banner:set'
export const PDP_DYNAMIC_BANNER_UPDATE = 'pdp:dynamic-banner:update'

export const PDP_DYNAMIC_CONTENT_OBSERVE = 'pdp:dynamic-content:observe'
export const PDP_DYNAMIC_CONTENT_UPDATE = 'pdp:dynamic-content:update'

export const PDP_ORDER_DATA_UPDATED = 'pdp:order-data:updated'

export const PDP_RESET_ORDER_STATE = 'pdp:order-data:reset'
export const PDP_RESET_PRODUCT_STATE = 'pdp:reset-products'

export const PDP_SELECT_PLAN = 'pdp:select-plan'
export const PDP_SELECT_PRODUCT_TYPE = 'pdp:select-product-type'

export const PDP_STICKY_ATC_OBSERVER = 'pdp:atc-sticky-observer'

export const PDP_VARIANT_QUANTITY_CHANGED = 'pdp:variant-quantity-changed'
export const PDP_VARIANT_FILTER = 'pdp:variant:filter'
export const PDP_VARIANT_SELECT = 'pdp:variant:select'
export const PDP_VARIANT_FILTER_UPDATED = 'pdp:variant-filter:updated'

export const PDP_VARIANT_SWATCH_SELECT = 'pdp:variant-swatch:select'
export const PDP_VARIANT_SWATCH_SELECTED = 'pdp:variant-swatch:selected'
export const PDP_VARIANT_SWATCH_UPDATED = 'pdp:variant-swatch:updated'

export const PDP_ENGRAVING_DIALOG_OPEN = 'pdp:engraving-dialog:open'
export const PDP_ENGRAVING_DIALOG_CLOSE = 'pdp:engraving-dialog:close'
export const PDP_ENGRAVING_DIALOG_CLOSED = 'pdp:engraving-dialog:closed'

export const PDP_ENGRAVING_UPDATE = 'pdp:engraving:update'

export const PDP_URL_UPDATE = 'pdp:url-update'
export const PDP_URL_UPDATED = 'pdp:url-updated'

export const PDP_HEADER_SCROLL = 'pdp:header:scroll'
export const PDP_HEADER_SHOW = 'pdp:header:show'

export const PDP_ORDER_ADDED_TO_CART = 'pdp:order:added-to-cart'

export const PDP_DRAWER_SWATCH_SELECT = 'pdp:drawer-swatch:select'
export const PDP_DRAWER_SWATCH_SELECTED = 'pdp:drawer-swatch:selected'
export const PDP_DRAWER_SWATCH_UPDATED = 'pdp:drawer-swatch:updated'
export const PDP_DRAWER_SWATCH_RESET = 'pdp:drawer-swatch:reset'
