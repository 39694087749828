type QuantityChange = 'increase' | 'decrease'

export const getDynamicBannerText = (
  quantityChange: {
    count: number
    direction: QuantityChange
  },
  language: Record<string, string>,
) => {
  const totalValues = 6
  const data = {
    increase: [],
    decrease: [],
  }

  for (let index = 1; index <= totalValues; index++) {
    const valueIncrease = language?.[`added${index}`]?.trim() || ''
    const valueDecrease = language?.[`removed${index}`]?.trim() || ''

    if (valueIncrease !== '') {
      data.increase.push(valueIncrease)
    }

    if (valueDecrease !== '') {
      data.decrease.push(valueDecrease)
    }
  }

  const groupTextValues =
    quantityChange.direction === 'increase' ? data.increase : data.decrease
  const index = quantityChange.count % groupTextValues.length

  return groupTextValues[index] || ''
}
