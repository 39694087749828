import { findAttributeValue } from '@/services/commerceEngine/transforms/helpers'
import { getLocaleConfig } from '@/helpers/locale-configs'
import { PDQ_INIT_EVENT } from './constants'
import { featureFlagsChecker } from '../feature-flags'
import { dispatchEvent } from '@/helpers/events/window'

export const notify = ({ threshold, currencySymbol }): void => {
  dispatchEvent(PDQ_INIT_EVENT, threshold)
}

const storeMap = {
  uk: 'cart.wearewild.com',
  eu: 'cart.wildrefill.com',
  us: 'cart.wilddeo.com',
  au: 'cart.wildrefill.co',
}

export type pdqResponse = {
  variant: string
  userId: string
  metadata: {
    threshold_amount: number
  }
}

export type fetchABVariantResponse = [
  data: null | pdqResponse,
  error: Error | null,
]

export const fetchABVariant = async (
  domain: string,
): Promise<fetchABVariantResponse> => {
  try {
    const data = await fetch(
      `https://${domain}/apps/pdq-proxy/shops/ab-variant`,
      {
        method: 'POST',
        body: JSON.stringify({
          userAgent: navigator.userAgent,
          navigatorLang: navigator.language,
          referrer: document.referrer,
          landingPage: window.location.origin + window.location.pathname,
        }),
      },
    )

    const json = await data.json()

    const { variant, userId, metadata } = json

    return [{ variant, userId, metadata }, null]
  } catch (error) {
    return [null, error]
  }
}

export const getFreeShippingMicrocopies = (): string | null => {
  try {
    const el = document.getElementById('pdqCopy') as HTMLElement

    if (!el) return null

    const copies: string | undefined = el.dataset.shippingMicrocopy

    if (!copies) return null

    return JSON.parse(copies)
  } catch (error) {
    return null
  }
}

export const initABTest = async ({
  attributes,
  storeCode,
  currencySymbol,
}): Promise<void> => {
  const domain: string | null = storeMap[storeCode] || null

  const [data, error] = (await fetchABVariant(domain)) as fetchABVariantResponse

  if (error) return

  const { variant, userId, metadata } = data as pdqResponse

  if (!variant || !userId || !metadata) return

  const variantInfo = metadata[variant]

  const threshold = variantInfo?.threshold_amount

  window._r_pdq = {
    threshold,
    variant,
  }

  window.CommerceEngine.emit('attributes/update', [
    ...attributes,
    {
      key: 'pdq_fst_session',
      value: userId,
    },
    {
      key: 'pdq_info',
      value: `${variant}|${threshold}`,
    },
  ])

  notify({
    threshold,
    currencySymbol,
  })
}

export const run = () => {
  if (typeof window === 'undefined') return

  const { storeCode, currencySymbol } = getLocaleConfig()

  const isPDQEnabled = featureFlagsChecker(storeCode)('pdq')
  if (!isPDQEnabled) return

  window.CommerceEngine?.on(
    'cart/updated',
    async (cart) => {
      if (!cart) return

      const {
        _raw: { attributes },
      } = cart

      if (!attributes.length) return

      const existingId = findAttributeValue(attributes, 'pdq_fst_session')

      if (!existingId) {
        return await initABTest({
          storeCode,
          currencySymbol,
          attributes,
        })
      }

      const pdqInfo = findAttributeValue(attributes, 'pdq_info')
      const [variant, threshold] = pdqInfo.split('|')

      const parsedThreshold = JSON.parse(threshold)

      window._r_pdq = {
        threshold: parsedThreshold,
        variant,
      }

      notify({
        currencySymbol,
        threshold: parsedThreshold,
      })
    },
    true,
  )
}
;(() => {
  run()
})()
