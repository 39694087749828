import {
  integrations,
  CUSTOM_TYPE,
  DEFERRED_SCRIPT_TYPE,
} from '@/legacy/config/integrations'

const initScript = ({ async = true, defer = true, id, src }) => {
  const script = document.createElement('script')
  script.async = async
  script.defer = defer
  script.id = id
  script.src = src
  document.head.appendChild(script)
}

export const initateDeferredScripts = (locale) => {
  if (!integrations) return

  const localeIntegrations = integrations[locale] || integrations.default

  if (!localeIntegrations) return

  const deferredScriptsByLocale = Object.keys(localeIntegrations).reduce(
    (allDefScripts, currentIntegration) => {
      const { type, scripts } = localeIntegrations[currentIntegration]

      if (!type || ![DEFERRED_SCRIPT_TYPE, CUSTOM_TYPE].includes(type))
        return allDefScripts

      if (!scripts || scripts.length === 0) return allDefScripts

      return [...allDefScripts, ...scripts]
    },
    [],
  )

  for (let index = 0; index < deferredScriptsByLocale.length; index++) {
    const script = deferredScriptsByLocale[index]
    initScript(script)
  }
}
