export const fetchStockLevels = `
  query fetchStockLevels($variantIds: [ID!]!) {
    nodes(ids: $variantIds) {
      ... on ProductVariant {
        id
        quantity: quantityAvailable
      }
    }
  }
`
