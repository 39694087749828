export class ImgixError extends Error {
  name = 'ImgixError'
}

export const resize = (
  url,
  { q = 80, auto = 'format', dpr = 1, trim, w, h, fit, m, blur, sat },
  fm = 'webp',
) => {
  const mask = m ? `&mask=${m}` : ''
  const width = w ? `&w=${w}` : ''
  const height = h ? `&h=${h}` : ''
  const cropFit = fit ? `&fit=${fit}` : ''
  const devicePixelRatio = dpr ? `&dpr=${dpr}` : ''
  const trimImage = trim ? `&trim=${trim}` : ''
  const blurImage = blur ? `&blur=${blur}` : ''
  const saturationImage = sat ? `&sat=${sat}` : ''
  const formatImage = fm ? `&fm=${fm}` : ''
  const mediaUrl = url.replace(
    'https://www.datocms-assets.com/60914',
    'https://cdn-image.wearewild.com',
  )

  return `${mediaUrl}?q=${q}&auto=${auto}${devicePixelRatio}${trimImage}${width}${height}${cropFit}${mask}${blurImage}${saturationImage}${formatImage}`
}

export const defaultSizes = [
  3600, 3200, 2800, 2400, 2200, 2000, 1800, 1600, 1400, 1200, 1000, 800, 600,
  400, 200,
]

const dimensions = (aspectRatio) => {
  const aspectRatioArray = aspectRatio.split(':')

  return {
    width: aspectRatioArray[0],
    height: aspectRatioArray[1],
  }
}

export const createSrc = ({
  url,
  aspectRatio = '1:1',
  sizes = defaultSizes,
  fit = 'crop',
  additionalParams = {},
}) => {
  if (!url) {
    console.error('No url provided')
    return ''
  }

  const aspectRatioDimensions = dimensions(aspectRatio)

  const smallestWidth = Math.min(...sizes)
  const smallestHeight =
    (smallestWidth / aspectRatioDimensions.width) * aspectRatioDimensions.height

  return resize(url, {
    w: smallestWidth,
    h: smallestHeight,
    fit,
    ...additionalParams,
  })
}

export const createSrcset = ({
  url,
  aspectRatio = '1:1',
  sizes = defaultSizes,
  fit = 'crop',
  additionalParams = {},
}) => {
  if (!url) {
    console.error('No url provided')
    return ''
  }

  const aspectRatioDimensions = dimensions(aspectRatio)

  return sizes.reduce((acc, size, index) => {
    const h =
      (size / aspectRatioDimensions.width) * aspectRatioDimensions.height

    const src = `${resize(url, {
      w: size,
      h,
      fit,
      ...additionalParams,
    })} ${size}w${index + 1 === sizes.length ? '' : ', '}`

    return acc + src
  }, '')
}

type ImagePropertiesType = {
  values: number[]
  sizes: string
  src: string
  srcset: string
}

export const getImageProperties = (
  url: string,
  sizes: number[],
  options = {},
): ImagePropertiesType => {
  if (sizes.length < 1) {
    throw new ImgixError('The image sizes cannot be empty')
  }

  if (sizes.length === 1) {
    const defaultSize = sizes.at(0)

    sizes = Array(3)
      .fill(null)
      .map((_, index) => defaultSize * (index + 1))
  }

  const sortedSizes = [...sizes].sort((a, b) => a - b)
  const defaultSize = `${sortedSizes.at(0)}px`
  const mediaConditions: string[] = []

  const serviceOptions = {
    url,
    sizes: sortedSizes,
    ...options,
  }

  for (let index = sortedSizes.length - 1; index >= 0; index--) {
    const entry = `(min-resolution: ${index + 1}x) ${sortedSizes[index]}w`

    mediaConditions.push(entry)
  }

  mediaConditions.push(defaultSize)

  return {
    values: sortedSizes,
    sizes: mediaConditions.join(', '),
    src: createSrc(serviceOptions),
    srcset: createSrcset(serviceOptions),
  }
}
