import type { GWPCampaign } from '@/components/GWPCampaigns/types'
import {
  LINE_ITEM_ATTR_GWP_FREE_GIFT,
  LINE_ITEM_ATTR_GWP_CUSTOMISABLE,
  LINE_ITEM_ATTR_GWP_GIFT_ID,
  LINE_ITEM_ATTR_GWP_GIFT_TAB,
} from '@/constants/cart-attributes'

export const formatCampaignProductsForAddToCart = (campaign: GWPCampaign) => {
  if (!campaign?.gifts?.length) return []

  const isCustomisable = campaign.customisableGifts === 'yes'
  const giftTab = campaign.giftOptions?.[0]?.id || ''

  const items = campaign?.gifts.map((product) => {
    return {
      id: product.attributes.id,
      quantity: product.quantity,
      customAttributes: [
        ...(isCustomisable
          ? [
              {
                key: LINE_ITEM_ATTR_GWP_CUSTOMISABLE,
                value: 'true',
              },
            ]
          : []),
        {
          key: LINE_ITEM_ATTR_GWP_GIFT_ID,
          value: product.attributes.productId,
        },
        {
          key: LINE_ITEM_ATTR_GWP_FREE_GIFT,
          value: 'true',
        },
        ...(giftTab
          ? [
              {
                key: LINE_ITEM_ATTR_GWP_GIFT_TAB,
                value: giftTab,
              },
            ]
          : []),
      ],
    }
  })

  return items.flat()
}
