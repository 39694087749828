const createNewId = () => {
  if (!crypto) return generateRandomId()

  return crypto.randomUUID()
}

// IOS 15 Fallback
const generateRandomId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

export default createNewId
