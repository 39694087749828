interface ScriptAttributes {
  id?: string
  async?: boolean
  defer?: boolean
  integrity?: string
  crossOrigin?: string
}

/**
 * Add a script tag to the HTML head of the current DOM document
 *
 * @param {string} url - The URL to an external file that contains the source code
 * @param {ScriptAttributes} attributes - Optional HTML attributes for the script tag
 *
 * @return void
 */
const script = (url: string, attributes: ScriptAttributes = {}): void => {
  const { id, async, defer, integrity, crossOrigin } = attributes

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url
  script.async = async === true
  script.defer = defer === true

  if (id?.length > 0) {
    script.id = id
  }

  if (integrity?.length > 0) {
    script.integrity = integrity
  }

  if (crossOrigin?.length > 0) {
    script.crossOrigin = crossOrigin
  }

  const isLoaded = document.getElementById(id) !== null

  if (isLoaded) {
    return
  }

  document.getElementsByTagName('head')[0].appendChild(script)
}

export default {
  script,
}

export { script as injectScript }
